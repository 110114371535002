import { gsap } from "gsap";

var $menuIcon = $('header .menu-wrapper #nav-icon');
var $header = $('header');
var $body = $('body');
var $navMenu = $('header .menu-wrapper .nav-menu');
var $navLink = $('.menu-content .menu-item-has-children a');
var $navLinkArrow = $('.menu-content .menu-item-has-children svg');
var breakpoint = 1024;
$('.menu-content .menu-item-has-children').removeClass('active');

if ($menuIcon.length > 0) {
    $menuIcon.click(function () {
        var menuLinks = gsap.utils.toArray($('li.menu-item'));
        var menuLinksAnchor = gsap.utils.toArray($('li.menu-item').children('.link-wrapper').children('a'));
        if ($header.hasClass('show-menu')) {
            hideMenu();
            if($('body').hasClass('custom-cursor-cube') || $('body').hasClass('custom-cursor-circle') || $('body').hasClass('custom-cursor-pyramid')) {
                $('body').removeClass('cursor-default');
            }
            if($header.hasClass('dropdown-padding') && $('.contact-offices').length > 0 && $(window).width() >= 1024) {
                if($('header .main-menu .nav-wrapper #menu-select-input .select-options li').length > 0) {
                    $('header .main-menu .nav-wrapper').addClass('show-dropdown');
                }
            }
            else if($header.hasClass('dropdown-padding') && !$body.hasClass('home')) {
                if($('header .main-menu .nav-wrapper #menu-select-input .select-options li').length > 0) {
                    $('header .main-menu .nav-wrapper').addClass('show-dropdown');
                }
            }
            gsap.to(menuLinks, {
                x: '-2rem',
                autoAlpha: 0, 
                stagger: 0.2,
                ease: 'power1',
            });
            gsap.to(menuLinksAnchor, {
                y: '2rem', 
                rotate:'4deg',
                stagger: 0.2,
                ease: 'power1',
            });
        }
        else {
            $body.addClass('no-scroll');
            $header.addClass('animate');
            setTimeout(function () {
                $header.addClass('show-menu');
            }, 100);
            if($header.hasClass('dropdown-padding')) {
                $('header .main-menu .nav-wrapper').removeClass('show-dropdown');
            }
            if($header.hasClass('show-search')) {
                $header.removeClass('animate-search');
                setTimeout(function() {
                    $header.removeClass('show-search');
                }, 100);
                $header.children('.icon-wrapper').remove();
                $('header .nav-wrapper #search #search-input').val('');
            }
            if($('body').hasClass('custom-cursor-cube') || $('body').hasClass('custom-cursor-circle') || $('body').hasClass('custom-cursor-pyramid')) {
                $('body').addClass('cursor-default');
            }
            gsap.to(menuLinks, {
                duration: 0.3,
                x: 0, 
                autoAlpha: 1, 
                stagger: 0.2,
                ease: 'power1',
            });
            gsap.to(menuLinksAnchor, {
                duration: 0.3,
                y: 0,
                rotate: 0,
                stagger: 0.2,
                ease: 'power1',
            });
        }
    });

    function hideMenu() {
        $header.removeClass('animate');
        setTimeout(function() {
            $header.removeClass('show-menu');
        }, 200);
        $body.removeClass('no-scroll');
        $('ul').removeClass('submenu-open');
        $('li').removeClass('active');
        $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
        if($('body').hasClass('search')) {
            $header.addClass('show-search');
            $('header .nav-wrapper #search').addClass('expand');
        }
    }

    if ($(window).width() < breakpoint) {
        $($navLinkArrow).click(function (e) {
            e.preventDefault();
            var childrenLinks = gsap.utils.toArray($(this).closest('li').children('ul').children('li.nav-drop-item'));
            var childrenLinksAnchor = gsap.utils.toArray($(this).closest('li').children('ul').children('li.nav-drop-item').children('.link-wrapper').children('a'));
            if ($(this).closest('li').hasClass('active')) {
                if ($(this).closest('li').children('ul').hasClass('second-children')) {
                    $(this).closest('li').children('.second-children').removeClass('submenu-open');
                }
                $(this).closest('li').removeClass('active');
                gsap.to(childrenLinks, {
                    x: '-2rem',
                    autoAlpha: 0, 
                    stagger: 0.125,
                    ease: 'power1',
                });
                gsap.to(childrenLinksAnchor, {
                    y: '2rem',  
                    rotate: '4deg',
                    stagger: 0.125,
                    ease: 'power1',
                });
                if($(this).closest('li').hasClass('contact-menu-link') || $(this).closest('li').hasClass('about-us-menu-link') || $(this).closest('li').hasClass('sustainability-menu-link') || $(this).closest('li').hasClass('investments-menu-link') || $(this).closest('li').hasClass('people-menu-link') || $(this).closest('li').hasClass('career-menu-link')) {
                    $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                }
            }
            else {
                if($(this).closest('li').children('ul').hasClass('first-children')) {
                    $(this).closest('li').siblings('li').removeClass('active');
                    $('li .second-children').removeClass('submenu-open');
                    $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                    $(this).closest('li').addClass('active');
                } 
                if ($(this).closest('li').children('ul').hasClass('second-children')) {
                    $(this).closest('li').children('.second-children').addClass('submenu-open');
                    $(this).closest('li').addClass('active');
                }
                gsap.to(childrenLinks, {
                    duration: 0.4,
                    x: 0, 
                    autoAlpha: 1, 
                    stagger: 0.15,
                    ease: 'power1',
                });
                gsap.to(childrenLinksAnchor, {
                    duration: 0.4,
                    y: 0,
                    rotate: 0,
                    stagger: 0.15,
                    ease: 'power1',
                });
                if ($(this).closest('li').hasClass('contact-menu-link') || $(this).closest('li').hasClass('about-us-menu-link') || $(this).closest('li').hasClass('sustainability-menu-link')) {
                    $('header').find('.icon-animation').addClass('show-circles');
                }
                else if ($(this).closest('li').hasClass('investments-menu-link')) {
                    $('header').find('.icon-animation').addClass('show-pyramids');
                }
                else if (($(this).closest('li').hasClass('people-menu-link') || $(this).closest('li').hasClass('career-menu-link'))) {
                    $('header').find('.icon-animation').addClass('show-cubes');
                }
            }
        });

        $($navLink).click(function (e) {
            e.preventDefault();
            var childrenLinks = gsap.utils.toArray($(this).closest('li').children('ul').children('li.nav-drop-item'));
            var childrenLinksAnchors = gsap.utils.toArray($(this).closest('li').children('ul').children('li.nav-drop-item').children('.link-wrapper').children('a'));
            if ($(this).closest('li').hasClass('active')) {
                window.location = e.target.href;
            }
            else {
                if($(this).closest('li').children('ul').hasClass('first-children')) {
                    $(this).closest('li').siblings('li').removeClass('active');
                    $('li .second-children').removeClass('submenu-open');
                    $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                    $(this).closest('li').addClass('active');
                } 
                if ($(this).closest('li').children('ul').hasClass('second-children')) {
                    $(this).closest('li').children('.second-children').addClass('submenu-open');
                    $(this).closest('li').addClass('active');
                }
                else if ($(this).closest('ul').hasClass('first-children') || ($(this).closest('ul').hasClass('second-children'))) {
                    window.location = e.target.href;
                }
        
                gsap.to(childrenLinks, {
                    duration: 0.4,
                    x: 0, 
                    autoAlpha: 1, 
                    stagger: 0.15,
                    ease: 'power1',
                });
                gsap.to(childrenLinksAnchors, {
                    duration: 0.4,
                    y: 0,
                    rotate: 0,
                    stagger: 0.15,
                    ease: 'power1',
                });
                if ($(this).closest('li').hasClass('contact-menu-link') || $(this).closest('li').hasClass('about-us-menu-link') || $(this).closest('li').hasClass('sustainability-menu-link')) {
                    $('header').find('.icon-animation').addClass('show-circles');
                }
                else if ($(this).closest('li').hasClass('investments-menu-link')) {
                    $('header').find('.icon-animation').addClass('show-pyramids');
                }
                else if (($(this).closest('li').hasClass('people-menu-link') || $(this).closest('li').hasClass('career-menu-link'))) {
                    $('header').find('.icon-animation').addClass('show-cubes');
                }
            }
        });
    }
    else {
        $('.menu-content ul li .link-wrapper').hover(function (e) {
            var $target = $(e.target);
            $target.closest('ul').children('li.active').removeClass('active');
            if ($target.siblings('ul').hasClass('first-children')) {
                $target.parent('li').addClass('active');
                $('ul.first-children').removeClass('btn-active');
                $target.siblings('ul.first-children').addClass('btn-active');
            }
            else if ($target.siblings('ul').hasClass('second-children')) {
                $target.closest('li.menu-item').addClass('active');
                if ($target.closest('li.menu-item').hasClass('active')) {
                    $('ul.second-children').removeClass('submenu-open btn-active');
                    $target.siblings('.second-children').addClass('submenu-open btn-active');
                    $target.closest('li').addClass('active');
                }
            }
            else {
                if ($target.closest('ul').hasClass('first-children')) {
                    $target.closest('li.menu-item').addClass('active');
                    $('ul.second-children').removeClass('submenu-open btn-active');
                    $('ul.first-children').removeClass('btn-active');
                    $target.closest('.first-children').addClass('btn-active');
                }
                if ($target.closest('ul').hasClass('second-children')) {
                    $target.closest('.second-children').addClass('submenu-open btn-active');
                }
                if(!$target.closest('ul').hasClass('first-children') && !$target.closest('ul').hasClass('second-children')) {
                    //$('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                }
                $target.closest('li').addClass('active');
                $target.closest('li.menu-item').addClass('active');
            }
            if($('header').find('.icon-animation').hasClass('show-anim') && (!$target.closest('ul').hasClass('first-children') && !$target.closest('ul').hasClass('second-children'))) {
                //$('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
            }
            if ($target.closest('li.menu-item').hasClass('contact-menu-link') || $target.closest('li.menu-item').hasClass('about-us-menu-link') || $target.closest('li.menu-item').hasClass('sustainability-menu-link')) {
                $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                $('header').find('.icon-animation').addClass('show-circles');
            }
            else if ($target.closest('li.menu-item').hasClass('investments-menu-link')) {
                $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                $('header').find('.icon-animation').addClass('show-pyramids');
            }
            else if (($target.closest('li.menu-item').hasClass('people-menu-link') || $target.closest('li.menu-item').hasClass('career-menu-link'))) {
                $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
                $('header').find('.icon-animation').addClass('show-cubes');
            }
            else {
                $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
            }

            $('li').each(function() {
                if($(this).hasClass('active') && ($(this).children('ul').length > 0)) {
                    var childrenLinks = gsap.utils.toArray($(this).children('ul').children('li'));
                    var childrenLinksAnchor = gsap.utils.toArray($(this).children('ul').children('li').children('.link-wrapper').children('a'));
                    if(childrenLinks.length > 0) {
                        gsap.to(childrenLinks, {
                            duration: 0.3,
                            x: 0, 
                            autoAlpha: 1, 
                            stagger: 0.125,
                            ease: 'power1',
                        });
                    }
                    if(childrenLinksAnchor.length > 0) {
                        gsap.to(childrenLinksAnchor, {
                            duration: 0.3,
                            y: 0,
                            rotate: 0,
                            stagger: 0.125,
                            ease: 'power1',
                        });
                    }
                }
                else {
                    if($(this).children('ul').length > 0) {
                        var childrenLinks = gsap.utils.toArray($(this).children('ul').children('li'));
                        var childrenLinksAnchor = gsap.utils.toArray($(this).children('ul').children('li').children('.link-wrapper').children('a'));
                        if(childrenLinks.length > 0) {
                            gsap.to(childrenLinks, {
                                duration: 0.01,
                                x: '-2rem', 
                                autoAlpha: 0, 
                                stagger: 0.15,
                                ease: 'power1',
                            });
                        }
                        if(childrenLinksAnchor.length > 0) {
                            gsap.to(childrenLinksAnchor, {
                                duration: 0.01,
                                y: '2rem', 
                                rotate: '4deg',
                                stagger: 0.15,
                                ease: 'power1',
                            });
                        }
                    }
                }
            })

        }, function (e) {
            var $target = $(e.target);
            if ($target.closest('ul').hasClass('first-children') && $target.siblings('ul').hasClass('second-children')) {
                $target.closest('ul.first-children').closest('li').addClass('active');
            }
            else if ($target.closest('ul').hasClass('first-children')) {
                $target.closest('li').removeClass('active');
            }
            else if ($target.closest('ul').hasClass('second-children')) {
                $target.closest('li').removeClass('active');
            }
            else {
                $('ul.first-children').removeClass('btn-active');
                $('ul.second-children').removeClass('submenu-open btn-active');
                $('li').removeClass('active');
                if($('header').find('.icon-animation').hasClass('show-circles')) {
                    $('header').find('.icon-animation').removeClass('show-circles');
                }
                else if($('header').find('.icon-animation').hasClass('show-cubes')) {
                    $('header').find('.icon-animation').removeClass('show-cubes');
                }
                else if($('header').find('.icon-animation').hasClass('show-pyramids')) {
                    $('header').find('.icon-animation').removeClass('show-pyramids');
                }
            }
        });

        $('.menu-content ul.first-children').hover(function (e) {
            var $target = $(e.target);
            $target.addClass('btn-active');
            $target.closest('ul.first-children').addClass('btn-active');
        });

        $('.menu-content ul li .link-wrapper').click(function (e) {
            var $target = $(e.target);
            var $href = $target.find('a').attr('href');
            window.location = $href;
        });
    }
}